import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Portal from '../components/portal'
import VideoPlayer from "../components/video-player"

import loadingIcon from "../images/loader.svg"
import './help.css'

import { withAuthenticator } from "aws-amplify-react"
import Amplify from "aws-amplify"
import { config } from '../config'


Amplify.configure(config)

class HelpPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      videoOpened: false,
      topics: [{
          id: 'topic1',
          title: 'Importer des Photos',
          description: "Mise en place du logiciel et accès pour vous permettre d'importer vos premières photos vous même.",
          duration: '13mn',
          videoUrl: '//common.the-red-frog.com/public/help/tuto1.mp4',
          posterUrl: '//common.the-red-frog.com/public/help/tuto1.jpg',
          width: 1368,
          height: 720
        }]
    }
  }

  showVideo (topic) {
    this.setState({
      selectedVideo: topic
    })
  }

  closePortal () {
    this.setState({
      selectedVideo: null
    })
  }

  renderVideo(item) {
    let aspectRatio = item.height / item.width
    let outerAspectRatio = 0.6666
    outerAspectRatio = aspectRatio // no need for consistency here - each video in its own modal

    const videoJsOptions = {
      itemKey: item.key,
      autoplay: false,
      controls: true,
      sources: [
        {
          src: item.videoUrl,
          type: "video/mp4",
        },
      ],
      poster: item.posterUrl,
    }

    return (
      <div
        style={{
          position: 'relative',
          width: "100%",
          paddingBottom: `${Math.floor((outerAspectRatio * 10000) / 100)}%`
        }}
      >
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '100%',
            maxWidth: `${item.width}px`,
            maxHeight: `${item.height}px`,
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden'
          }}>
            <div style={
              (aspectRatio > outerAspectRatio) ? 
              {
                height: "100%"
              }
              :
              {
              width: "100%",
              paddingBottom: `${Math.floor((aspectRatio * 10000) / 100)}%`
            }}>
              <VideoPlayer {...videoJsOptions} />
            </div>
          </div>

        
      </div>
    )
  }


  render () {
    let portalContent = ''
    if (this.state.selectedVideo) {
      portalContent = <Portal>
        <div className="modal">
          <div className="modal-background"></div>
            <div className="modal-video">
              <div></div>
              {this.renderVideo(this.state.selectedVideo)}
              <button className="modal-close is-large" aria-label="close" onClick={() => this.closePortal()}></button>
            </div>
          </div>
        </Portal>
    }

    let content =  <div className="loader" style={{ margin: '10rem' }}><img src={loadingIcon} alt="loading" /></div>

    if (this.state.topics.length) {
      content = this.state.topics.map(topic => <button key={topic.id} className='help-item-wrap' onClick={() => this.showVideo(topic)} >
        <div className='topic-title'>
          <span>{topic.title}</span> <span className='topic-duration'>({topic.duration})</span></div>
        <div>{topic.description}</div>
        
      </button>)
    }

    return  <Layout menu="HELP">
        <SEO title="Help" />
        <h1>Tutoriels</h1>
        <div className='help-items-wrap'>
          {content}
        </div>
        {portalContent}
      </Layout>
  }
}

export default withAuthenticator(HelpPage, {
  includeGreetings: false
 })
